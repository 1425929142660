<script lang="ts" setup>
import type { EventApi, CalendarOptions, DayCellContentArg, EventMountArg, EventClickArg } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { type DateClickArg } from '@fullcalendar/interaction';
import zhLocale from '@fullcalendar/core/locales/zh-cn';
import dayjs from 'dayjs';
import { useDebounceFn } from '@vueuse/core';
import { ref, onMounted, onUnmounted, watch, nextTick } from 'vue';
import { $fetch } from '@@home/plugins/fetch';
import useMarkdown from '@@home/hooks/markdown';
import { useAsyncData } from '@@home/hooks/async-data';

const marked = useMarkdown();

const dialogPos = ref({
  left: 0,
  top: 0,
});
const eventRef = ref<HTMLElement | null>(null);
const allSameIdEvents = ref<NodeListOf<Element> | null>(null);
const popoverRef = ref<HTMLElement | null>(null);
// 存储点击的活动信息
const eventClickInfo = ref<EventApi | null>(null);
const isOpen = ref(false);
const RIGHT_DISTANCE = 300

watch(allSameIdEvents, (newVal, oldVal) => {
  if (oldVal && newVal && newVal[0]?.id === oldVal[0]?.id) {
    return;
  }

  newVal && newVal.forEach((element) => {
    element.closest('.fc-daygrid-event-harness')?.firstElementChild?.classList.add('bg-#1180FF!', 'text-white!');
  });

  oldVal && oldVal.forEach((element) => {
    element.closest('.fc-daygrid-event-harness')?.firstElementChild?.classList.remove('bg-#1180FF!', 'text-white!');
  });
});

watch(eventRef, (newVal, oldVal) => {
  if (newVal) {
    eventRef.value?.querySelector('.point')?.classList.add('bg-white');
    eventRef.value?.querySelector('.fc-daygrid-block-event')?.classList.add('arrow');
  }
  if (oldVal) {
    oldVal.querySelector('.point')?.classList.remove('bg-white');
    oldVal.querySelector('.fc-daygrid-block-event')?.classList.remove('arrow');
  }
});

const getDialogPos = () => {
  if (!eventRef.value) {
    return;
  }

  //获取点击元素的整体元素坐标信息
  const rectRight = popoverRef.value ? popoverRef.value.getBoundingClientRect().right : eventRef.value?.getBoundingClientRect().right;
  const rectLeft = popoverRef.value ? popoverRef.value.getBoundingClientRect().left : eventRef.value?.getBoundingClientRect().left;
  const rectTop = eventRef.value?.getBoundingClientRect().top;
  //获取当前屏幕宽度
  const currentPageWidth = window.innerWidth;

  //获取点击元素的页面的连贯宽度
  const eventWidth = popoverRef.value ? popoverRef.value.offsetWidth : eventRef.value.offsetWidth;

  //判断元素到屏幕左右侧边缘的距离是否足够
  const rightDis = (currentPageWidth - rectRight > RIGHT_DISTANCE);
  const leftDis = (rectLeft > 300);
  //根据不同的情况判断popover的横坐标
  const rightPos = rightDis ? (rectRight + 4) : leftDis ? (rectLeft - 284) : (rectLeft + 0.5 * eventWidth - 140);
  const topPos = (!rightDis && !leftDis) ? (rectTop + window.scrollY) : (rectTop + window.scrollY);
  //将坐标赋值给popover
  dialogPos.value.left = rightPos;
  dialogPos.value.top = topPos;
}

const handleEventClick = (e: EventClickArg) => {
  const clickedEventId = e.event.id;

  e.jsEvent.preventDefault();

  allSameIdEvents.value = document.querySelectorAll(`#fc-event-${clickedEventId}`);
  eventRef.value = e.el.closest('.fc-daygrid-event-harness');
  popoverRef.value = e.el.closest('.fc-popover');

  getDialogPos()
  e.jsEvent.stopPropagation()
  eventClickInfo.value = e.event;
  if (!isOpen.value) {
    openDialog();
  }

  // 使用 window.getSelection() 获取当前选中的文本范围
  const selection = window.getSelection();
  // 检查是否有选中的文本
  if (selection?.toString() !== '') {
    // 清空选中的文本范围
    selection?.removeAllRanges();
  }
}

const handleClick = (e: DateClickArg) => {
  e.jsEvent.stopPropagation()
  closeDialog();
}

const closeDialog = () => {
  eventClickInfo.value = null;
  eventRef.value = null;
  allSameIdEvents.value = null;
  isOpen.value = false;
}

const openDialog = () => {
  isOpen.value = true
}

const closeDialogEvent = () => {
  closeDialog();
}

const onWindowResize = useDebounceFn(() => {
  getDialogPos();
}, 100);

const calendarOptions = ref<CalendarOptions>({
  plugins: [dayGridPlugin, interactionPlugin],
  height: 740,
  locales: [zhLocale],
  eventDisplay: 'auto',
  firstDay: 0,
  headerToolbar: {
    start: 'prev,title,next',
    end: '',
  },
  eventClick: handleEventClick,
  dateClick: handleClick,
  events: [],
  dayMaxEventRows: 3,
  handleWindowResize: true,
  moreLinkText: (n) => `还有${n}项活动...`,
  dayHeaderContent: (date) => date.text.slice(1),
  dayCellContent: (cell: DayCellContentArg) => {
    if (cell.isToday) {
      return {
        html: `<span class="mr-4px box-border inline-block w-24px h-24px text-white font-medium text-14px bg-brand-primary rounded-1/2 leading-24px text-center">${cell.date.getDate()}</span>日`,
      };
    }
    return cell.dayNumberText;
  },
  dayPopoverFormat: {
    day: 'numeric',
  },
  eventDidMount: (info: EventMountArg) => {
    if (info.el.parentElement?.classList.contains('fc-daygrid-event-harness-abs')) {
      return;
    }

    nextTick(() => {
      const el = info.el.querySelector('.event-title');
      if (el) {
        el.innerHTML = info.event.title;
      }
    });
  },
});

const formatDateRange = (startDate: string, endDate: string) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  if (start.year() !== end.year() || start.month() !== end.month()) {
    return `${start.format('M月D日')} - ${end.format('M月D日')}`;
  }

  if (start.date() !== end.date()) {
    return `${start.format('M月D日')} - ${end.format('D日')}`;
  }

  if (start.format('HH:mm:ss') === '00:00:00' && end.format('HH:mm:ss') === '23:59:59') {
    return `${start.format('M月D日')}`;
  }

  return `${start.format('M月D日 HH:mm')} - ${end.format('HH:mm')}`;
};

const { data: calendarList } = useAsyncData('home:calendar', () => $fetch('/activity/v1/list'), {
  server: false,
  onAfterHandle() {
    calendarOptions.value.events = calendarList.value.list;
  },
});

onMounted(() => {
  document.addEventListener('click', closeDialogEvent)
  window.addEventListener('resize', onWindowResize)
});

onUnmounted(() => {
  document.removeEventListener('click', closeDialogEvent)
  window.removeEventListener('resize', onWindowResize)
});
</script>

<template>
  <section class="page-home-calendar bg-white">
    <div class="h-1080px w-full bg-[rgba(45,45,45,0.02)] text-#2d2d2d">
      <h2 class="mx-auto mb-54px mt-0 h-70px pt-106px text-center text-50px font-medium leading-70px">活动日历</h2>
      <div class="mx-auto h-700px w-1360px <2xl:max-w-1200px">
        <FullCalendar :options="calendarOptions" class="full-calendar">
          <template #eventContent="arg">
            <b
              class="h-20px flex items-center text-12px font-400 leading-20px"
              :id="`fc-event-${arg.event.id}`"
            >
              <span v-show="dayjs(arg.event.start).format('M月D日') === dayjs(arg.event.end).format('M月D日')" class="point mx-4px inline-block h-4px w-4px rounded-4px bg-[#1180FF]"></span>
              <b class="event-title inline-block w-170px overflow-hidden text-12px font-400 leading-20px <2xl:w-152px">{{ arg.isStart ? arg.event.title : '' }}</b>
            </b>
          </template>
        </FullCalendar>
      </div>

      <ClientOnly>
        <div class="event-dialog absolute z-10000 mx-auto box-border w-280px bg-white" v-show="isOpen" @click.stop>
          <h2 class="line-clamp-2 m-16px mb-8px box-border max-h-48px w-248px text-16px font-500 leading-24px">{{ eventClickInfo?.title }}</h2>
          <div class="paragraph m-0px bg-[#F8F8F9] px-16px py-8px text-12px color-hex-9A9EA6 font-400 leading-18px" v-if="eventClickInfo?.extendedProps?.info">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="marked.parse(eventClickInfo.extendedProps.info)"></div>
          </div>
          <div class="mx-16px my-8px flex text-14px leading-24px">
            <span class="inline-block flex-shrink-0 font-medium">时间：</span>
            <span class="inline-block font-400">{{ formatDateRange(eventClickInfo?.startStr ?? '', eventClickInfo?.endStr ?? '') }}</span>
          </div>
          <div class="mx-16px mb-16px flex text-14px leading-24px">
            <span class="inline-block flex-shrink-0 font-medium">地点：</span>
            <span class="inline-block font-400">
              <!-- {{ eventClickInfo?.extendedProps?.type == 1 ? eventClickInfo?.extendedProps?.city : $t('events.regionOnline') }}{{ (eventClickInfo?.extendedProps?.city || eventClickInfo?.extendedProps?.type != 1) && eventClickInfo?.extendedProps?.place ? '·' : '' }}{{ eventClickInfo?.extendedProps?.place }} -->
              {{ eventClickInfo?.extendedProps?.place }}
            </span>
          </div>
        </div>
      </ClientOnly>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.page-home-calendar {
  &:deep(.full-calendar) {
    --fc-neutral-bg-color: "#fff";
    --fc-event-text-color: "inherit";
    --fc-today-bg-color: none;
    --fc-event-bg-color: #cfe5ff;
    --fc-event-border-color: none;
    --fc-button-bg-color: none;
    --fc-button-border-color: 0;
    --fc-button-text-color: none;
    --fc-button-hover-border-color: none;
    --fc-button-hover-bg-color: none;
    --fc-button-active-bg-color: none;
    --fc-button-active-border-color: none;
    --fc-border-color: rgba(154, 158, 166, 0.2);

    &.fc {
      th {
        text-align: left;
      }
    }

    .fc-scrollgrid-section {
      tbody {
        background-color: #fff;

        td {
          border: 1px solid rgba(154, 158, 166, 0.2);
        }
      }
    }

    .fc-toolbar-chunk {
      div {
        display: flex;
        align-items: center;
        height: 16px;
      }

      .fc-button {
        height: 16px;
        margin-bottom: 12px;
        padding: 0;
        font-size: 16px;
      }

      .fc-icon {
        font-size: 16px;
      }

      h2 {
        height: 16px;
        margin: 0 16px;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
      }

      .fc-button-primary {
        border: 0 solid;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .fc-scrollgrid-sync-inner {
      a {
        color: #2d2d2d;
        font-weight: 400;
        font-size: 16px;
      }
    }

    .fc-header-toolbar {
      margin-bottom: 0;
    }

    .fc-event {
      height: 20px;
      margin: 0 4px 4px 4px;
      color: #2d2d2d;
      border: 0;
      border-radius: 2px;

      .fc-event-main {
        margin-top: 2px;
        cursor: pointer;

        b {
          display: block;
          width: 170px;
          margin-left: 12px;
          overflow: hidden;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          text-overflow: ellipsis;

          b {
            margin-left: 0;
          }
        }
      }

      b {
        b {
          margin-left: 0;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .fc-daygrid-event-harness {
      margin-bottom: 2px;

      a {
        cursor: pointer;
      }
    }

    .fc-daygrid-day-top {
      flex-direction: row;

      a {
        margin-top: 4px;
        margin-left: 4px;
        padding: 4px;
      }
    }

    .fc-daygrid-day {
      height: 110px;
    }

    .fc-col-header-cell {
      height: 48px;
      border-right: 0;
      border-bottom: 1px solid rgba(154, 158, 166, 0.2);
      border-left: 0;

      .fc-col-header-cell-cushion {
        height: 48px;
        line-height: 48px;
      }
    }

    .fc-scrollgrid {
      border-top: 0;
      border-left: 0;

      th {
        border-right: 0;
        border-left: 0;
      }

      .fc-scrollgrid-sync-table {
        border-left: 0 solid;
      }
    }

    .fc-daygrid-day-bottom {
      .fc-more-link {
        box-sizing: border-box;
        width: 184px;
        height: 20px;
        margin-left: 2px;
        padding: 0;
        padding-left: 12px;
        color: #9a9ea6;
        font-size: 12px;
        line-height: 20px;

        &:focus-visible {
          outline: none;
        }
      }
    }

    .fc-popover {
      padding: 16px 20px;

      .fc-popover-body {
        min-width: 186px;
        margin-top: 4px;
        padding: 0;

        .fc-daygrid-event-harness {
          position: relative;
          display: flex;

          .fc-event {
            padding: 0;
            border-radius: 0;

            .fc-event-main {
              margin-top: 0;
            }
          }

          .fc-daygrid-block-event::after {
            position: absolute;
            top: 0;
            left: 182px;
            display: block;
            border-top: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 10px solid #cfe5ff;
            content: "";
          }

          .arrow::after {
            position: absolute;
            top: 0;
            left: 182px;
            display: block;
            border-top: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 10px solid #1180ff;
            content: "";
          }
        }
      }

      .fc-popover-header {
        height: 20px;
        margin-left: 4px;
        padding: 0;

        .fc-icon {
          width: 20px;
          height: 20px;
          color: #2d2d2d;
          font-size: 8px;
          line-height: 20px;
          background: #f8f8f9;
          border-radius: 10px;
        }

        .fc-popover-title {
          color: #2d2d2d;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    .fc-daygrid-dot-event:hover {
      background: unset;
    }
  }

  .event-dialog {
    top: v-bind("dialogPos.top + 'px'");
    left: v-bind("dialogPos.left + 'px'");
    border: 1px solid rgba(154, 158, 166, 0.2);
    box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.2);
    transform: translateY(calc(-50% + 10px));
    transition: all linear .2s;

    &:deep(p) {
      a {
        color: #1180ff;
        text-decoration: none;
      }
    }

    .paragraph {
      &:deep() {
        p {
          @apply my-0 ;
        }

        a {
          color: #1180ff;
          text-decoration: none;
          list-style: none;
        }
      }
    }
  }
}
</style>
