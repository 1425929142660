<script lang="ts" setup>
// import NavigateButton from '../../../components/NavigateButton.vue';
import consult from '../../../assets/home/consult.png';
import openLaw from '../../../assets/home/open-law.png';
import openProject from '../../../assets/home/open-project.png';
import openBrand from '../../../assets/home/open-brand.png';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const openList = [
  {
    key: 1,
    img: consult,
    message: t('home.openSourceService.introduce1'),
  },
  {
    key: 2,
    img: openLaw,
    message: t('home.openSourceService.introduce2'),
  },
  {
    key: 3,
    img: openProject,
    message: t('home.openSourceService.introduce3'),
  },
  {
    key: 4,
    img: openBrand,
    message: t('home.openSourceService.introduce4'),
  }
]
</script>

<template>
  <section class="page-home-opensource-service">
    <h2 class="m-0"> {{ $t('home.openSourceService.title') }}</h2>
    <!-- <NavigateButton to="/">
      {{ $t('home.openSourceService.content') }}
    </NavigateButton> -->
    <div class="open-list">
      <div class="piece" v-for="(item) in openList" :key="item.key">
        <img :data-src="item.img" class="lazyload" :alt="item.message">
        <span class="description">
          {{ item.message }}
        </span>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.page-home-opensource-service {
  height: 600px;
  background: url(../../../assets/home/openservice-bg.jpg) no-repeat;
  background-color: #fff;
  background-size: cover;

  h2 {
    margin-bottom: 16px;
    padding-top: 102px;
    color: #2d2d2d;
    font-weight: 500;
    font-size: 50px;
    text-align: center;
  }

  .open-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 48px auto 0;

    @apply w-1360px <2xl:w-1200px;
  }

  .piece {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 240px;
    background: linear-gradient(135deg, #edf6ff 0%, #fff 100%);
    border-radius: 4px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
    transform: translateY(0);
    // cursor: pointer;
    transition: transform .5s;

    @apply w-328px <2xl:w-288px;

    img {
      width: 104px;
      height: 104px;
      margin-top: 44px;
      margin-bottom: 16px;
      transition: all .7s .2s;
    }

    .description {
      color: #2d2d2d;
      font-weight: 500;
      font-size: 24px;
    }

    &:hover {
      transform: translateY(-16px);

      img {
        width: 120px;
        height: 120px;
        margin-bottom: 0;
      }
    }
  }
}
</style>
