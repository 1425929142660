<script lang="ts" setup>
</script>

<template>
  <section class="page-home-bottom-banner relative after:(absolute bottom-0 left-0 right-0 top-0 block bg-white content-empty -z-1)">
    <div class="bottom-banner-container mx-auto bg-cover bg-no-repeat">
      <h2 class="m-0 text-center font-medium">{{ $t('home.bottomBanner.title') }}</h2>
      <a href="https://atomgit.com/" target="_blank" class="btn mx-auto box-border block cursor-pointer border-0 bg-transparent bg-no-repeat text-center font-medium no-underline">
        {{ $t('home.bottomBanner.view') }}
      </a>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.page-home-bottom-banner {
  width: 100%;
  background-color: #e8f1f8;

  .bottom-banner-container {
    height: 290px;
    background-image: url(@ssr/home/assets/home/bottom-banner-bg.jpg);

    h2 {
      padding-top: 100px;
      color: #1180ff;
      font-size: 40px;
      line-height: 56px;
    }

    .btn {
      width: 144px;
      margin-top: 15px;
      padding-top: 9px;
      padding-bottom: 16px;
      color: #097bff;
      font-size: 18px;
      background-image: url(@ssr/home/assets/home/bottom-banner-button.svg);
      background-size: 100% 100%;
    }
  }
}
</style>
