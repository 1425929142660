<script lang="ts" setup>
import { computed } from 'vue';
import { marked } from 'marked';
import { $fetch } from '@@home/plugins/fetch';

import { useAsyncData } from '@@home/hooks/async-data';
import NavigateButton from '@ssr/home/components/NavigateButton.vue';

const { data: insightList } = useAsyncData(
  'home:insight',
  () => $fetch('/insight/v1/list', { data: { limit: 1 } }),
  {
    transform: (data) => data?.[0] ?? null,
  }
);

const markedContent = computed(() => {
  return insightList.value?.keyPoints ? marked.parse(insightList.value.keyPoints) : '';
});
</script>

<template>
  <section class="page-home-insight relative after:absolute after:bottom-0 after:left-0 after:right-0 after:top-0 after:block after:bg-white after:content-empty after:-z-1">
    <div class="insight-container mx-auto w-1360px bg-cover bg-no-repeat py-122px <2xl:w-1200px">
      <h2 class="m-0 text-center font-medium">开源态势洞察</h2>
      <NavigateButton to="/information/insight" class="mt-16px leading-22px">查看往期内容</NavigateButton>

      <div class="report-box mt-48px flex justify-between px-80px py-64px <2xl:py-98px <2xl:pl-62px <2xl:pr-48px" v-if="insightList">
        <div class="report-box__cover flex-shrink-0 <2xl:mt-19px">
          <div class="pic group">
            <small class="text-14px text-white">{{ insightList.title }}</small>
            <h3 class="mb-0 mt-10px text-24px text-white font-medium <2xl:mt-5px <2xl:text-20px">开源态势洞察</h3>
            <a :href="insightList.fileUrl" class="btn absolute z-1 block cursor-pointer bg-transparent no-underline opacity-0 transition-opacity duration-300 group-hover:opacity-100" target="_blank">阅读完整报告</a>
          </div>
        </div>

        <div class="report-box__content flex-grow">
          <h3 class="truncate-ellipsis relative m-0 font-medium after:absolute after:bottom-0 after:left-0 after:right-0 after:content-empty">
            <span class="w-full text-center leading-36px">《开源态势洞察》{{ insightList.title }}</span>
          </h3>
          <h4 class="relative m-0 text-center font-medium after:absolute after:left-0 after:right-0 after:content-empty">
            <span class="relative z-1 inline-block">本期主要看点</span>
          </h4>

          <!-- eslint-disable-next-line vue/no-v-html -->
          <div class="report-box__content__text" v-html="markedContent"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.page-home-insight {
  width: 100%;
  background-color: #fff;

  .insight-container {
    h2 {
      color: rgba(45, 45, 45, 1);
      font-size: 50px;
      line-height: 70px;
    }

    .report-box {
      background: #fff;
      border: 1px solid rgb(154, 158, 166, 0.2);
      border-radius: 4px;
      box-shadow: 0 0 20px 0 rgb(17, 128, 255, 0.05);

      .report-box__cover {
        .pic {
          background-image: url(@@home/assets/home/open-book.png);

          @apply relative box-border h-512px w-441px bg-contain bg-no-repeat pl-62px pt-110px;
          @apply after:absolute after:bottom-0 after:left-0 after:right-0 after:top-0 after:mb-33px after:ml-42px after:mr-87px after:mt-30px after:bg-[rgba(45,45,45,0.5)] after:opacity-0 after:transition-opacity after:duration-300 after:content-empty;
          @apply hover:after:opacity-100;
          @apply <2xl:w-353px <2xl:h-410px <2xl:pl-50px <2xl:pt-90px <2xl:after:ml-34px <2xl:after:mb-27px <2xl:after:mt-25px <2xl:after:mr-69px;

          .btn {
            padding: 10px 28px;
            color: #fff;
            font-size: 20px;
            border: 1px solid #fff;
            border-radius: 10000px;

            @apply top-43% left-25% <2xl:left-20%;
          }
        }
      }

      .report-box__content {
        @apply ml-87px <2xl:ml-64px;

        h3 {
          color: #fff;
          font-size: 24px;
          background: linear-gradient(225.99deg, #5be6ff 0%, #1180ff 100%);
          border-radius: 4px 4px 0 0;

          @apply mt-34px <2xl:mt-0;

          span {
            padding: 14px;
            padding-bottom: 22px;
          }

          &::after {
            height: 8px;
            background: linear-gradient(135deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.75) 100%);
          }
        }

        h4 {
          margin-top: 8px;
          color: rgba(17, 128, 255, 1);
          font-size: 20px;

          span {
            padding: 0 10px;
            background-color: #fff;
          }

          &::after {
            top: 50%;
            height: 2px;
            background-color: rgba(17, 128, 255, 1);
            transform: translateY(-50%);
          }
        }

        .report-box__content__text {
          margin-top: 16px;
          color: #000;
          font-size: 20px;
          line-height: 40px;

          &::v-deep(ul) {
            padding-left: 1.2em;
          }
        }
      }
    }
  }
}
</style>
