<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import Banner from './components/Banner.vue'
import ScrollInfo from './components/ScrollInfo.vue'
import ExploreProjects from './components/ExploreProjects.vue';
import OpenSourceService from './components/OpenSourceService.vue';
import Welfare from './components/Welfare.vue';
import Journalism from './components/Journalism.vue';
import Contributors from './components/Contributors.vue';
import BottomBanner from './components/BottomBanner.vue';
import Insight from './components/Insight.vue';
import Calendar from './components/Calendar.vue';

const { locale } = useI18n();
</script>

<template>
  <main class="page-home">
    <Banner />
    <ScrollInfo />
    <ExploreProjects />
    <OpenSourceService />
    <Welfare />
    <Insight v-if="locale === 'zh-CN'" />
    <Calendar v-if="locale === 'zh-CN'" />
    <Journalism v-if="locale === 'zh-CN'" />
    <Contributors />
    <BottomBanner />
  </main>
</template>

<style lang="scss" scoped>
</style>
