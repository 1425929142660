<script lang="ts" setup>
import { $fetch } from '@@home/plugins/fetch';
import { CONTRIBUTOR_CATEGORY_SUB_PLATINUM } from '@@home/utils/constants';
import { useAsyncData } from '@@home/hooks/async-data';
import NavigateButton from '@ssr/home/components/NavigateButton.vue';

const { data: contributors } = useAsyncData('home:contributors', () => $fetch('/donor/v1/show', { data: { sc: CONTRIBUTOR_CATEGORY_SUB_PLATINUM, limit: -1 } }))
</script>

<template>
  <section class="page-home-contributors relative after:absolute after:bottom-0 after:left-0 after:right-0 after:top-0 after:block after:bg-white after:content-empty after:-z-1">
    <div class="contributors-container mx-auto">

      <img class="title-image mx-auto block" src="@ssr/home/assets/home/contributor-icon-1.svg" alt="">

      <div class="title text-center">
        <h2 class="relative z-0 m-0 inline-block font-medium after:absolute after:block after:content-empty after:-z-1">{{ $t('home.contributors.title') }}</h2>
      </div>

      <NavigateButton to="/cooperation" class="mt-16px leading-22px">{{ $t('home.contributors.donationDetails') }}</NavigateButton>

      <div class="contribution-methods flex justify-between">
        <div class="contribution-methods__item money flex items-center">
          <div class="text overflow-y-auto">
            <h3 class="m-0 font-medium">{{ $t('home.contributors.donationOffunds') }}</h3>
            <small class="font-medium">{{ $t('home.contributors.donationOfFundsIntroduce') }}</small>
            <p class="m-0 font-normal">{{ $t('home.contributors.donationOfFundsIntroduce1') }}</p>
          </div>
          <div class="pic flex-shrink-0">
            <img data-src="@ssr/home/assets/home/contributor-icon-3.png" class="lazyload" alt="">
          </div>
        </div>
        <div class="contribution-methods__item project flex items-center">
          <div class="text overflow-y-auto">
            <h3 class="m-0 font-medium">{{ $t('home.contributors.projectDonation') }}</h3>
            <small class="font-medium">{{ $t('home.contributors.projectDonation1') }}</small>
            <p class="m-0 font-normal">{{ $t('home.contributors.projectDonation2') }}</p>
          </div>
          <div class="pic flex-shrink-0">
            <img data-src="@ssr/home/assets/home/contributor-icon-4.png" class="lazyload" alt="">
          </div>
        </div>
      </div>

      <h2 class="platinum-title m-0 text-center font-medium">{{ $t('home.contributors.platinumDonor') }}</h2>

      <NavigateButton to="/cooperation/partner" class="mt-16px leading-22px">{{ $t('home.contributors.moreDonation') }}</NavigateButton>

      <ul class="platinum-contributors m-0 flex flex-shrink flex-wrap list-none p-0">
        <li v-for="item in contributors" :key="item.donorId">
          <img :data-src="item.logo" class="lazyload" :alt="item.donorName" :title="item.donorName">
        </li>
      </ul>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.page-home-contributors {
  width: 100%;
  color: #2d2d2d;
  background-color: rgba(45, 45, 45, 0.02);

  .contributors-container {
    @apply py-90px max-w-1360px <2xl:max-w-1200px <2xl:pb-74px;

    .title-image {
      width: 144px;
      height: 144px;
    }

    .title {
      padding-bottom: 5px;
    }

    h2 {
      font-size: 50px;

      &::after {
        right: -25px;
        bottom: -5px;
        left: -25px;
        height: 20px;
        background: linear-gradient(90deg, #1180ff 0%, #5be6ff 100%);
        border-radius: 10px;
        opacity: 0.2;
      }
    }

    .contribution-methods {
      margin-top: 48px;

      .contribution-methods__item {
        background-color: rgba(0, 129, 255, 0.05);
        background-image: url(@ssr/home/assets/home/contributor-icon-2.svg);
        background-position: right bottom;
        background-size: 278px 170px;
        border-radius: 4px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08);

        @apply bg-no-repeat w-664px h-200px <2xl:w-584px;

        .text {
          @apply h-136px ml-46px <2xl:ml-32px;

          h3 {
            color: #1180ff;
            font-size: 24px;
            line-height: 40px;
          }

          small {
            display: block;
            margin-top: 8px;
            font-size: 16px;
            line-height: 24px;
          }

          p {
            margin-top: 16px;
            color: #3d3d3d;
            line-height: 24px;
          }
        }

        .pic {
          @apply w-184px <2xl:w-168px;

          img {
            transition: all 0.2s;

            @apply w-144px h-144px <2xl:h-136px <2xl:w-136px;
          }
        }

        &:hover {
          .pic {
            img {
              @apply w-154px h-154px <2xl:h-144px <2xl:w-144px;
            }
          }
        }
      }
    }

    .platinum-title {
      margin-top: 72px;
      line-height: 70px;
    }

    .platinum-contributors {
      margin-top: 48px;

      li {
        width: 213px;
        height: 78px;
        background-color: #fff;
        box-shadow: 0 2px 14px 0 rgba(8, 56, 107, 0.08), inset 0 1px 0 0 #fff;
        transition: all 0.3s;

        &:hover {
          box-shadow: 0 2px 14px 0 rgba(8, 56, 107, 0.2), inset 0 1px 0 0 #fff;
        }

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        @screen 2xl {
          & {
            margin-left: 16px;
          }

          &:nth-child(n+7) {
            margin-top: 16px;
          }

          &:nth-child(6n + 1) {
            margin-left: 0;
          }
        }

        @screen lt-2xl {
          & {
            margin-left: 32px;
          }

          &:nth-child(n+6) {
            margin-top: 32px;
          }

          &:nth-child(5n + 1) {
            margin-left: 0;
          }
        }
      }
    }
  }
}
</style>
