<script lang="ts" setup>
import { toRefs } from 'vue'
import InfoAbout from './InfoAbout.vue';
import InfoWills from './InfoWills.vue';

const props = defineProps({
  pageFlag: String,
})

const { pageFlag } = toRefs(props);
</script>

<template>
  <div class="template-modal mx-auto">
    <div class="template-box">
      <InfoAbout v-show="pageFlag === 'second-screen'" />
      <InfoWills v-show="pageFlag === 'third-screen'" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.template-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1360px;
  height: calc(100vh - 216px);
  padding-top: 32px;
  background-color: rgba(255, 255, 255, 0.4);
}

.template-box {
  @apply w-684px h-276px py-60px px-56px <2xl:w-600px <2xl:h-300px <2xl:py-40px;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.75) 100%);
  border: 2px solid;
  border-radius: 4px;
  border-image: linear-gradient(225deg, rgba(0, 129, 255, 1), rgba(0, 129, 255, 0), rgba(0, 129, 255, 0), rgba(0, 129, 255, 1)) 2 2;
  backdrop-filter: blur(12px);
}
</style>
