<script lang="ts" setup>
import { ref, onMounted } from 'vue';

const containerEl = ref<HTMLElement | null>(null);

function scrollToTop() {
  const c = containerEl.value?.scrollTop ?? 0;
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    containerEl.value?.scrollTo(0, c - c / 8);
  }
  else {
    setTimeout(scrollToBottom, 1000);
  }
}

function scrollToBottom() {
  const c = containerEl.value?.scrollTop ?? 0;
  const scrollHeight = containerEl.value?.scrollHeight ?? 0;
  const height = containerEl.value?.clientHeight ?? 0;

  if (c < scrollHeight - height) {
    setTimeout(() => window.requestAnimationFrame(scrollToBottom), 100);
    containerEl.value?.scrollTo(0, c + 1);
  }
  else {
    setTimeout(scrollToTop, 1000);
  }
}

onMounted(() => {
  scrollToBottom();
});
</script>

<template>
  <div>
    <h3 class="m-0">{{ $t('about.infoAbout.title') }}</h3>
    <div class="h-255px overflow-hidden <2xl:h-270px" ref="containerEl">
      <p class="about-message">
        {{ $t('about.infoAbout.briefIntroduction1') }}
      </p>
      <p class="about-message">
        {{ $t('about.infoAbout.briefIntroduction2') }}
      </p>
      <p class="about-message">
        {{ $t('about.infoAbout.briefIntroduction3') }}
      </p>
      <p class="about-message">
        {{ $t('about.infoAbout.briefIntroduction4') }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
h3 {
  margin-bottom: 8px;
  color: #2d2d2d;
  font-weight: 400;
  font-size: 24px;
  text-align: center;
}

.about-message {
  margin: 0;
  color: #2d2d2d;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-indent: 2em;
}
</style>
