<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router';

const props = withDefaults(defineProps<{
  to: RouteLocationRaw,
  align?: 'left'| 'center'| 'right',
}>(), {
  align: 'center',
})
</script>

<template>
  <RouterLink :class="['navigate-btn', align]" :to="props.to">
    <div class="handle-btn">
      <span><slot></slot></span>
      <span class="i-home-icons:arrow-right text-brand-primary"></span>
    </div>
  </RouterLink>
</template>

<style lang="scss" scoped>
.navigate-btn {
  display: flex;
  align-items: center;
  color: #1180ff;
  font-weight: 400;
  font-size: 16px;
  text-decoration: none;

  &.left {
    justify-content: left;
  }

  &.center {
    justify-content: center;
  }

  &.right {
    justify-content: right;
  }

  img {
    width: 16px;
    height: 16px;
    margin-left: 4px;
  }

  .handle-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
</style>
