<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { useWindowScroll, watchThrottled } from '@vueuse/core';

import Info from './Info.vue';
import InfoTemplate from './InfoTemplate.vue';

const flag = ref<string>('');
const pageFlag = ref<string>('');
const scrollEle = ref<HTMLElement | null>(null);

const { y } = useWindowScroll();

onMounted(() => {
  watchThrottled(y, (value) => {
    const offsetTop: number = scrollEle.value?.offsetTop ?? 0;

    if (value > offsetTop && value < offsetTop + 500) {
      flag.value = 'second-screen-show show';
      pageFlag.value = 'second-screen';
      return;
    }
    if (value > offsetTop + 500) {
      flag.value = 'third-screen-show show';
      pageFlag.value = 'third-screen';
      return;
    }
    if (value < offsetTop) {
      flag.value = '';
      pageFlag.value = '';
      return;
    }
  }, {
    throttle: 30
  });
})
</script>

<template>
  <section :class="['page-home-scroll-box', flag]" ref="scrollEle">
    <div class="scroll-screen">
      <Info :class="['first-screen', flag]" />
      <div :class="['second-screen']">
        <InfoTemplate :page-flag="pageFlag" />
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.page-home-scroll-box {
  position: relative;
  z-index: -1;
  height: 200vh;

  &.show .second-screen {
    z-index: 10;
    opacity: 1;
  }
}

.scroll-screen {
  z-index: -1;
  width: 100%;
  height: 100%;
}

.second-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all .5s;
}

.show .first-screen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  transition: all .5s;
}
</style>
