<script lang="ts" setup>
import { computed } from 'vue';
import { $fetch } from '@@home/plugins/fetch';
import { useAsyncData } from '@@home/hooks/async-data';
import NavigateButton from '@@home/components/NavigateButton.vue';
import InfiniteMarquee from '@@home/components/InfiniteMarquee.vue';

const { data: incubateList } = useAsyncData('home:incubate', () => $fetch('/project/v1/list', { data: { status: 2, limit: 8 } }));
const { data: donateList } = useAsyncData('home:donate', () => $fetch('/project/v1/list', { data: { status: 1 } }));

const donateMarqueeList = computed(() => [
  donateList.value?.slice(0, 5),
  donateList.value?.slice(5, 10),
  donateList.value?.slice(10, 15),
]);
</script>

<template>
  <section class="page-home-explore-projects">
    <h2 class="m-0">{{ $t('home.exploreProjects.ourProject') }}</h2>

    <div class="all-project">
      <NavigateButton to="/projects">{{ $t('home.exploreProjects.allItems') }}</NavigateButton>
    </div>

    <div class="explore-list relative m-auto mx-auto mt-48px w-1360px <2xl:mt-42px <2xl:max-w-1200px">
      <img src="@@home/assets/home/explore-bg.svg" class="explore-bg" alt="">
      <div class="flex flex-wrap">
        <RouterLink custom v-slot="{ navigate }" :to="`/project/${item.id}`" v-for="item in incubateList" :key="item.id">
          <div class="piece ml-32px cursor-pointer <2xl:ml-16px" @click="navigate">
            <div class="piece-name">
              <span class="title h-[32px] max-w-[217px] truncate leading-[32px]">
                {{ item.title }}
              </span>
              <span class="desc">{{ $t('home.exploreProjects.incubate') }}</span>
            </div>
            <div class="piece-desc truncate">
              {{ item.projectValue }}
            </div>
            <div class="flex overflow-hidden">
              <template v-for="(tag, index) in item.label" :key="index">
                <div class="tags-piece truncate px-8px" :title="tag" v-if="tag">{{ tag }}</div>
              </template>
            </div>
          </div>
        </RouterLink>
      </div>
    </div>

    <div class="explore-marquee relative mx-auto">
      <InfiniteMarquee v-for="(item, index) in donateMarqueeList" :key="index" :speed="40" :reverse="index % 2 ? true : false" class="mt-20px">
        <div v-for="piece in item" :key="piece.id" class="marquee-card">
          <RouterLink :to="`/project/${piece.id}`" class="block truncate no-underline" :title="piece.title">
            {{ piece.title }}
          </RouterLink>
        </div>
      </InfiniteMarquee>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.page-home-explore-projects {
  box-sizing: border-box;
  padding-top: 112px;
  padding-bottom: 112px;
  background-color: #fff;
  box-shadow: 0 -20px 30px 0 rgba(0, 0, 0, 0.05);

  h2 {
    color: #2d2d2d;
    font-weight: 500;
    font-size: 50px;
    line-height: 70px;
    text-align: center;
  }

  .all-project {
    margin-top: 16px;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
  }

  .explore-list {
    .explore-bg {
      position: absolute;
      width: 300px;
      height: 300px;
      transform: translate(-190px, -212px);
    }

    .piece {
      @apply w-316px h-150px px-32px py-24px <2xl:w-288px <2xl:px-16px <2xl:py-24px;
      position: relative;
      z-index: 0;
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
      transition: box-shadow .2s;

      &:nth-child(4n + 1) {
        margin-left: 0;
      }

      &:nth-child(n + 5) {
        @apply mt-32px <2xl:mt-16px;
      }

      &:hover {
        box-shadow: 0 0 20px 0 rgba(17, 128, 255, 0.3);

        &::after {
          opacity: 1;
        }

        .title {
          color: #1180ff;
        }
      }

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        display: block;
        background: url(@@home/assets/home/explore-list-bg.png) no-repeat;
        background-position: bottom right;
        background-size: 67px 102px;
        opacity: 0;
        transition: opacity .3s;
        content: "";
      }

      .piece-name {
        display: flex;
        align-items: center;

        .title {
          margin-right: 8px;
          color: #2d2d2d;
          font-weight: 500;
          font-size: 20px;
        }

        .desc {
          height: 16px;
          padding-right: 5px;
          padding-left: 5px;
          color: #fe7300;
          font-weight: 500;
          font-size: 10px;
          line-height: 16px;
          text-align: center;
          word-break: keep-all;
          border: 1px solid #fe7300;
          border-radius: 4px;
        }
      }

      .piece-desc {
        margin: 8px 0 16px;
        color: #9a9ea6;
        font-weight: 400;
        font-size: 16px;
      }

      .tags-piece {
        box-sizing: border-box;
        min-width: 64px;
        max-width: 68px;
        height: 24px;
        color: rgba(134, 144, 156, 0.6);
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        text-align: center;
        border: 1px solid rgba(75, 86, 102, 0.1);
        border-radius: 4px;

        + .tags-piece {
          margin-left: 8px;
        }
      }
    }
  }

  .explore-marquee {
    @apply w-1360px mt-104px <2xl:mt-66px <2xl:max-w-1200px;

    &::before,
    &::after {
      position: absolute;
      z-index: 2;
      width: 250px;
      height: 100%;
      content: "";
      pointer-events: none;
    }

    &::before {
      top: 0;
      left: 0;
      background: linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
    }

    &::after {
      top: 0;
      right: 0;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
    }

    .marquee-card {
      box-sizing: border-box;
      width: 280px;
      height: 72px;
      margin-right: 12px;
      margin-left: 12px;
      font-size: 20px;
      line-height: 72px;
      text-align: center;
      background: linear-gradient(180deg, rgba(39, 183, 255, 0.05) 0%, rgba(17, 128, 255, 0.1) 100%);
      border-radius: 4px;

      a {
        padding-right: 20px;
        padding-left: 20px;
        color: #2d2d2d;
      }
    }
  }
}
</style>
