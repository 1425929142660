<script lang="ts" setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useElementVisibilityOnce } from '@@home/utils';

import translateIcon from '@ssr/home/assets/home/open-translate.png';
import lessonIcon from '@ssr/home/assets/home/series-lesson.png';
import sofaIcon from '@ssr/home/assets/home/law-sofa.png';

const { t } = useI18n();
const lawList = [
  {
    title: t('home.welfare.lawList.title1'),
    name: t('home.welfare.lawList.name1'),
    description: t('home.welfare.lawList.description1'),
    icon: translateIcon,
    url: '/law/translate',
  },
  {
    title: t('home.welfare.lawList.title2'),
    name: t('home.welfare.lawList.name2'),
    description: t('home.welfare.lawList.description2'),
    icon: lessonIcon,
    url: '/law/lecture',
  },
  {
    title: t('home.welfare.lawList.title3'),
    name: t('home.welfare.lawList.name3'),
    description: t('home.welfare.lawList.description3'),
    icon: sofaIcon,
    url: '/law/salon',
  },
];

const topEl = ref();
const isTopVisible = useElementVisibilityOnce(topEl);
const bottomEl = ref();
const isBottomVisible = useElementVisibilityOnce(bottomEl);
</script>

<template>
  <section class="page-home-welfare">
    <div class="welfare-container mx-auto">
      <div class="welfare-top" ref="topEl" :class="{ active: isTopVisible }">
        <div class="welfare-title">
          <h2 class="z-1 m-0 before:-z-1">
            {{ $t('home.welfare.title1') }}<br>
            {{ $t('home.welfare.title2') }}
          </h2>
          <!-- <NavigateButton to="/" align="left">
            {{ $t('home.welfare.content') }}
          </NavigateButton> -->
        </div>
        <div class="welfare-content">
          <div class="school min-h-156px">
            <i18n-t keypath="home.welfare.topic1.title" scope="global" tag="div" class="content-title whitespace-nowrap">
              <strong class="text-brand-primary font-medium">{{ $t('home.welfare.topic1.titlePrimary') }}</strong>
            </i18n-t>
            <i18n-t keypath="home.welfare.topic1.content" scope="global" tag="div" class="content-description h-75px overflow-y-auto">
              <strong class="font-semibold">{{ $t('home.welfare.topic1.contentPrimary') }}</strong>
            </i18n-t>
          </div>
          <div class="competition">
            <i18n-t keypath="home.welfare.topic2.title" scope="global" tag="div" class="content-title whitespace-nowrap">
              <strong class="text-brand-primary font-medium">{{ $t('home.welfare.topic2.titlePrimary') }}</strong>
            </i18n-t>
            <i18n-t keypath="home.welfare.topic2.content" scope="global" tag="div" class="content-description h-75px overflow-y-auto">
              <strong class="font-semibold">{{ $t('home.welfare.topic2.contentPrimary') }}</strong>
            </i18n-t>
          </div>
        </div>
      </div>

      <div class="welfare-bottom" ref="bottomEl" :class="{ active: isBottomVisible }">
        <img src="@@home/assets/home/open-law.svg" class="open-law" alt="">
        <i18n-t keypath="home.welfare.lawTitle" scope="global" tag="h3" class="m-0">
          <strong class="text-brand-primary font-medium">{{ $t('home.welfare.lawTitlePrimary') }}</strong>
        </i18n-t>
        <div class="law-list">
          <div v-for="item in lawList" :key="item.title" class="law-piece">
            <div class="law-piece-title mx-auto">
              <span class="law-piece-card"></span>
              <span class="truncate-ellipsis box-border px-10px" :title="item.title"><span>{{ item.title }}</span></span>
            </div>
            <div class="law-piece-content">
              <RouterLink :to="item.url" class="block h-full no-underline">
                <div class="law-piece-border box-border pb-15px">
                  <img :data-src="item.icon" class="lazyload law-piece-img" :alt="item.name">
                  <span class="truncate-ellipsis law-piece-name box-border flex-shrink-0 px-15px text-center" :title="item.name"><span>{{ item.name }}</span></span>
                  <span class="law-piece-description flex-grow overflow-y-auto">{{ item.description }}</span>
                </div>
              </RouterLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.page-home-welfare {
  width: 100%;
  background-color: #fff;

  .welfare-container {
    padding-top: 53px;
    padding-bottom: 68px;

    @apply w-1360px <2xl:w-1200px;
  }
}

.welfare-top {
  position: relative;
  display: flex;
  justify-content: space-around;
  transform: translateY(15%);
  opacity: 0;
  transition: transform 0.5s, opacity 0.5s;

  &.active {
    transform: translateY(0);
    opacity: 1;
  }

  &::before {
    position: absolute;
    top: 12px;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-color: #0081ff;
    border-radius: 50%;
    opacity: 0.5;
    content: "";

    @apply left-560px <2xl:left-400px;
  }
}

.welfare-title {
  flex: 1;
  margin-top: 187px;

  h2 {
    position: relative;
    margin-bottom: 32px;
    color: #2d2d2d;
    font-weight: 500;
    font-size: 50px;
    line-height: 70px;

    &::before {
      position: absolute;
      top: -85px;
      left: -100px;
      display: inline-block;
      width: 200px;
      height: 200px;
      background-color: #0081ff;
      border-radius: 50%;
      opacity: 0.3;
      content: "";
    }
  }
}

.welfare-content {
  width: 876px;
  height: 559px;
  margin-right: -50px;
  background: url(../../../assets/home/welfare-content.svg) no-repeat;
  background-size: 100% 100%;

  .school {
    margin-top: 63px;
    margin-left: 186px;
  }

  .competition {
    margin-top: 48px;
    margin-left: 130px;
  }

  .content-title {
    width: 560px;
    margin-bottom: 16px;
    color: #2d2d2d;
    font-weight: 500;
    font-size: 28px;
    line-height: 45px;
  }

  .content-description {
    width: 560px;
    color: #3d3d3d;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }

  .description-title {
    font-weight: 600;
  }
}

.welfare-bottom {
  position: relative;
  margin-top: -22px;
  transform: translateY(15%);
  opacity: 0;
  transition: transform 0.5s, opacity 0.5s;

  &.active {
    transform: translateY(0);
    opacity: 1;
  }

  .open-law {
    position: absolute;
    top: -51px;
    width: 101px;
    height: 90px;

    @apply left-458px <2xl:left-378px;
  }

  h3 {
    margin-bottom: 48px;
    color: #2d2d2d;
    font-weight: 500;
    font-size: 32px;
    text-align: center;

    .header-title {
      color: #1180ff;
    }
  }
}

.law-list {
  display: flex;
  justify-content: space-between;
}

.law-piece {
  position: relative;
  height: 328px;
  // cursor: pointer;

  @apply w-400px <2xl:w-368px;

  &:nth-child(1) {
    .law-piece-title {
      background: linear-gradient(296deg, #f7ad00 0%, #fe7300 100%);
    }

    .law-piece-card {
      background: linear-gradient(45deg, rgba(255, 156, 73, 1) 0%, rgba(255, 194, 53, 1) 100%);
    }
  }

  &:nth-child(2) {
    .law-piece-title {
      background: linear-gradient(296deg, #5be6ff 0%, #1180ff 100%);
    }

    .law-piece-card {
      background: linear-gradient(45deg, rgba(79, 161, 255, 1) 0%, rgba(114, 234, 255, 1) 100%);
    }
  }

  &:nth-child(3) {
    .law-piece-title {
      background: linear-gradient(119deg, #ff0018 0%, #ff7784 100%);
    }

    .law-piece-card {
      background: linear-gradient(45deg, rgba(255, 97, 112, 1) 0%, rgba(255, 150, 160, 1) 100%);
    }
  }

  &:hover &-title {
    transform: translateY(-16px);
  }

  &-content::before {
    position: absolute;
    top: -2px;
    right: 0;
    width: 0;
    height: 2px;
    transition: width .3s;
    content: "";
  }

  &:hover &-content::before {
    width: 100%;
  }

  &-content::after {
    position: absolute;
    top: 0;
    right: -1px;
    width: 2px;
    height: 0;
    transition: height .3s;
    content: "";
  }

  &:hover &-content::after {
    height: 100%;
  }

  &-border::before {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 0;
    height: 2px;
    transition: width .3s;
    content: "";
  }

  &:hover &-border::before {
    width: 100%;
  }

  &-border::after {
    position: absolute;
    bottom: 0;
    left: -1px;
    width: 2px;
    height: 0;
    transition: height .3s;
    content: "";
  }

  &:hover &-border::after {
    height: 100%;
  }

  &:nth-child(1) &-content {
    &::before {
      background: linear-gradient(90deg, rgba(254, 115, 0, 0%) 30%, rgba(254, 115, 0, 100%) 100%);
    }

    &::after {
      background: linear-gradient(0deg, rgba(254, 115, 0, 0%) 30%, rgba(254, 115, 0, 100%) 100%);
    }
  }

  &:nth-child(1) &-border {
    &::before {
      background: linear-gradient(90deg, rgba(254, 115, 0, 1), rgba(254, 115, 0, 0) 70%);
    }

    &::after {
      background: linear-gradient(0deg, rgba(254, 115, 0, 1), rgba(254, 115, 0, 0) 70%);
    }
  }

  &:nth-child(2) &-content {
    &::before {
      background: linear-gradient(90deg, rgba(0, 129, 255, 0%) 30%, rgba(0, 129, 255, 100%) 100%);
    }

    &::after {
      background: linear-gradient(0deg, rgba(0, 129, 255, 0%) 30%, rgba(0, 129, 255, 100%) 100%);
    }
  }

  &:nth-child(2) &-border {
    &::before {
      background: linear-gradient(90deg, rgba(0, 129, 255, 1), rgba(0, 129, 255, 0) 70%);
    }

    &::after {
      background: linear-gradient(0deg, rgba(0, 129, 255, 1), rgba(0, 129, 255, 0) 70%);
    }
  }

  &:nth-child(3) &-content {
    &::before {
      background: linear-gradient(90deg, rgba(255, 0, 24, 0%) 30%, rgba(255, 0, 24, 100%) 100%);
    }

    &::after {
      background: linear-gradient(0deg, rgba(255, 0, 24, 0%) 30%, rgba(255, 0, 24, 100%) 100%);
    }
  }

  &:nth-child(3) &-border {
    &::before {
      background: linear-gradient(90deg, rgba(255, 0, 24, 1), rgba(255, 0, 24, 0) 70%);
    }

    &::after {
      background: linear-gradient(0deg, rgba(255, 0, 24, 1), rgba(255, 0, 24, 0) 70%);
    }
  }

  &-border {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &-title {
    position: relative;
    width: 272px;
    height: 150px;
    padding-top: 10px;
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
  }

  &-card {
    position: absolute;
    top: 48px;
    left: 50%;
    width: 200px;
    height: 40px;
    border-radius: 4px;
    transform: translateX(-50%);
  }

  &-content {
    position: absolute;
    top: 48px;
    height: 280px;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.75) 100%);
    border-radius: 4px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(10px);

    @apply w-400px <2xl:w-368px;
  }

  &-img {
    width: 100px;
    height: 100px;
    margin-top: 40px;
    margin-bottom: 16px;
  }

  &-name {
    margin-bottom: 8px;
    color: #2d2d2d;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }

  &-description {
    width: 320px;
    color: #3d3d3d;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
  }
}
</style>
