<script lang="ts" setup>
</script>

<template>
  <div class="h-full flex flex-col items-center justify-center">
    <h3 class="m-0 text-center">{{ $t('home.infoWills.title') }}</h3>
    <p class="wills-message m-0 text-center">
      {{ $t('home.slogan') }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
h3 {
  margin-bottom: 16px;
  color: #2d2d2d;
  font-weight: 400;
  font-size: 24px;
}

.wills-message {
  color: #2d2d2d;
  font-weight: 500;
  font-size: 32px;
  line-height: 60px;
}
</style>
