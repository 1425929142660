<script lang="ts" setup>
import { computed } from 'vue';
import CountTo from '@@home/components/CountTo.vue';
import { $fetch } from '@@home/plugins/fetch';

import calendarNumber from '../../../assets/home/calendar-number.png'
import projectNumber from '../../../assets/home/project-number.png';
import repotNumber from '../../../assets/home/repo-number.png';
import developerNumber from '../../../assets/home/developer-number.png';
import { useI18n } from 'vue-i18n';
import { useAsyncData } from '@@home/hooks/async-data';

const { t } = useI18n();

const { data: infoList } = useAsyncData('home:info', () => $fetch('/statistic/v1/major'));

const infoMessage = computed(() => [
  {
    key: 1,
    img: calendarNumber,
    number: infoList.value?.incubatingCount,
    message: t('home.info.numberIntroduce1'),
  },
  {
    key: 2,
    img: projectNumber,
    number: infoList.value?.storageCount,
    message: t('home.info.numberIntroduce2'),
  },
  {
    key: 3,
    img: repotNumber,
    number: infoList.value?.committerCount,
    message: t('home.info.numberIntroduce3'),
  },
  {
    key: 4,
    img: developerNumber,
    number: infoList.value?.starCount,
    message: t('home.info.numberIntroduce4'),
  }
]);
</script>

<template>
  <div class="info">
    <div class="info-box w-1360px <2xl:max-w-1200px">
      <div class="info-logo"></div>
      <div class="info-message">
        <div class="piece" v-for="item in infoMessage" :key="item.key">
          <img :data-src="item.img" class="lazyload" :alt="item.message">
          <span class="description">
            <span class="number">
              <CountTo
                :end-val="item.number >= 100000 ? item.number / 10000 : item.number"
                :suffix="item.number >= 100000 ? 'w+' : '+'"
              />
            </span>
            <span class="message">{{ item.message }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.info {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.info-box {
  height: calc(100vh - 64px);
  margin: 0 auto;
  padding: 32px 0;
}

.info-logo {
  width: 100%;
  height: calc(100vh - 248px);
  background: url(../../../assets/home/info-logo.png) no-repeat;
  background-position: center;
  background-size: auto 100%;
}

.info-message {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 58px 103px;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 20px 100px 0 rgba(0, 0, 0, 0.06);
}

.piece {
  display: flex;
  align-items: center;
  height: 68px;

  img {
    width: 68px;
    height: 68px;
    margin-right: 16px;
  }
}

.number {
  color: #2d2d2d;
  font-weight: 500;
  font-size: 40px;
}

.message {
  color: #2d2d2d;
  font-weight: 400;
  font-size: 16px;
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
</style>
